@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-video-carousel {
        background-color: $light-purple;
        padding: 70px 0;
        margin-top: 70px!important;
        margin-bottom: 70px!important;
        overflow: hidden;

        .title-wrapper{
            text-align: center;
            @include col-start(2);
            @include col(10);
            margin-bottom: 40px;
        }

        .swiper-container{
            @include col-start(2);
            @include col(10);
        }

        [class*="swiper-button"]{
            position: absolute;
            z-index:9;
            top: 82.5%;
            width: 23px;
            height: 49px;
            padding: 20px;
            background-size: 23px 49px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../images/ui/arrow.png");
        }

        .video-click-dispatcher{
            position: absolute;
            cursor: pointer;
            z-index:9;
            top:0;
            left:0;
            right:0;
            bottom:0;
            opacity:0.01;

            &:hover {
                + .video-poster{
                    .play-button {
                        filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))!important;
                        transform: translate(-50%, -50%) scale(0.45)!important;
                        transition: transform ease 250ms!important;
    
                        svg {
                            transform: scale(1.05)!important;
                        }
                    }
                } 
            }
        }
    
        .swiper-button-next{
            right: -16px;
            transform: translateY(-50%);
            transition: background-position-x 0.3s ease;
            &:after{
                content: '';
            }

            &:hover{
                background-position-x:28px;
            }
        }
        .swiper-button-prev{
            left: -15px;
            transform: translateY(-50%) rotate(180deg);
            transition: background-position-x 0.3s ease;
    
            &:after{
                content: '';
            }

            &:hover{
                background-position-x:28px;
            }
        }

        .media-wrapper{
            height: auto;

            &.primary-video{
                @include col(10);
                @include col-start(2);
                margin-bottom: 75px;
            }

            &.tile-video{
                min-width: calc(6 *(100% - 16px) / (var(--column-amount) ));
                max-width: calc(6 *(100% - 16px) / (var(--column-amount) ));
            }

            .media{
                border-radius: 21px;
                overflow: hidden;
                width: inherit;
                position: relative;
                display: grid;

                &:not(.html-player) {
                    aspect-ratio: 16/9; // do not force aspect-ratio on html5 player
                }


                iframe {
                    width: 100%;
                    height: 100%;
                }
    
                video {
                    width: 100%;
                    height: 100%;
                }
                img {
                    user-select: none;
                    pointer-events: none;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
    
                .video-player, .video-poster, .image-wrapper {
                    grid-area: 1 / 1;
                    border-radius: 20px;
                    overflow: hidden;
                }

                .video-poster {
                    position: absolute;
                    z-index: 2;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 20px;
                    cursor: pointer;
                    transition: opacity 0.75s ease;
    
                    &.inactive {
                        pointer-events: none;
                        opacity: 0;
                    }
    
                    &:hover {
                        .play-button {
                            filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4));
                            transform: translate(-50%, -50%) scale(0.45);
                            transition: transform ease 250ms;
    
                            svg {
                                transform: scale(1.05);
                            }
                        }
                    }
    
                    .play-button {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0.3);
                        z-index: 3;
                        transition: transform ease 250ms;
                        cursor: pointer;
    
                        display: grid;
                        place-items: center;
                        padding: 0;
    
                        border: 6px solid #fff;
                        background: rgba(0,0,0,0.2);;
                        opacity:0.7;
                        border-radius: 50%;
                        aspect-ratio: 1;

                        svg{
                            width: 115px;
                            height: 115px;

                            *{
                                fill: #fff;
                            }

                            rect{
                                display: none;
                            }
                        }
                    }
                }
            }

            .credit{
                margin: 20px 0;
                text-align: center;
            }
        }

        @media only screen and (max-width: $xs-max) {
            padding: 50px 0;
            margin-top: 50px!important;
            margin-bottom: 50px!important;

            .video-click-dispatcher{
                display: none;
            }

            .swiper-container{
                @include col-start(1);
                @include col(12);
            }

            [class*="swiper-button"]{
               display:none;
            }

            .media-wrapper{
                height: auto;
    
                &.primary-video{
                    display: none;
                }
    
                &.tile-video{
                    min-width: 93%;
                    max-width: 93%;
                }

                .credit{
                    margin-bottom:0;
                }
            }
    
        }
    }
}