@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.cards-with-content{
    margin-block: 150px;

    $ease: cubic-bezier(.2,.67,.39,.97);
    $duration: 0.6s;

    &.small-margin {
        margin-block: 56px;
    }

     &.projects-cards{
        .centered-content{
            .card-with-content{
                .buttons{
                    justify-content: flex-end;
                }
            }
        }
    }

    .centered-content {
        .card-with-content{
            position: relative;
            background: map-get($colors-cards-with-content, bg);
            border-radius: 20px;
            margin-bottom: 16px;
            padding: 24px;
            min-height: 370px;
            box-sizing: border-box;
            display:flex;
            flex-direction: column;
            justify-content: space-between;

            &:last-child{
                margin-bottom:0;
            }

            &.homepage{
                min-height: 170px;

                .infos{
                    .title{
                        width: 60%;
                        padding-bottom: 60px;
                    }
                }

                .buttons{
                    position: absolute;
                    left: 24px;
                    bottom: 24px;
                }

                .hover-wrapper{
                    display:none;
                }

                img{
                    border-radius: 10px;
                    object-fit: cover;
                }
            }

            &:hover{
                &[data-color="lavender"]{
                    .infos{
                        color: map-get($colors-backgrounds, lavender);
                    }
                    .buttons{

                        .tag{
                            border-color: map-get($colors-backgrounds, lavender);
                            color: map-get($colors-backgrounds, lavender);
                        }

                        .discover{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .reservation{
                            border-color: map-get($colors-backgrounds, lavender);
                            background-color: map-get($colors-backgrounds, lavender);
                        }
                    }
                }
                &[data-color="yellow"]{
                    .infos{
                        color: map-get($colors-backgrounds, yellow);
                    }
                    .buttons{

                        .tag{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .discover{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .reservation{
                            border-color: map-get($colors-backgrounds, yellow);
                            background-color: map-get($colors-backgrounds, yellow);
                        }
                    }
                }
                &[data-color="aqua"]{
                    .infos{
                        color: map-get($colors-backgrounds, aqua);
                    }
                    .buttons{

                        .tag{
                            border-color: map-get($colors-backgrounds, aqua);
                            color: map-get($colors-backgrounds, aqua);
                        }

                        .discover{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .reservation{
                            border-color: map-get($colors-backgrounds, aqua);
                            background-color: map-get($colors-backgrounds, aqua);
                        }
                    }
                }
                &[data-color="teal"]{
                    .infos{
                        color: map-get($colors-backgrounds, teal);
                    }
                    .buttons{

                        .tag{
                            border-color: map-get($colors-backgrounds, teal);
                            color: map-get($colors-backgrounds, teal);
                        }

                        .discover{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .reservation{
                            border-color: map-get($colors-backgrounds, teal);
                            background-color: map-get($colors-backgrounds, teal);
                        }
                    }
                }
                &[data-color="pink"]{
                    .infos{
                        color: map-get($colors-backgrounds, pink);
                    }
                    .buttons{

                        .tag{
                            border-color: map-get($colors-backgrounds, pink);
                            color: map-get($colors-backgrounds, pink);
                        }

                        .discover{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .reservation{
                            border-color: map-get($colors-backgrounds, pink);
                            background-color: map-get($colors-backgrounds, pink);
                        }
                    }
                }
                &[data-color="blue"]{
                    .infos{
                        color: map-get($colors-backgrounds, blue);
                    }
                    .buttons{

                        .tag{
                            border-color: map-get($colors-backgrounds, blue);
                            color: map-get($colors-backgrounds, blue);
                        }

                        .discover{
                            border-color: map-get($colors-backgrounds, yellow);
                            color: map-get($colors-backgrounds, yellow);
                        }

                        .reservation{
                            border-color: map-get($colors-backgrounds, blue);
                            background-color: map-get($colors-backgrounds, blue);
                        }
                    }
                }

                .hover-wrapper{
                    opacity:1;

                    img{
                        scale: 1.033;
                    }
                }
            }

            >a{
                position: absolute;
                top:0;
                left:0;
                z-index:3;
                width: 100%;
                height: 100%;
            }

            .infos{
                position: relative;
                z-index: 2;
                display:flex;
                justify-content: space-between;

                transition: color $duration $ease;

                .extra-title{
                    width: 30%;
                    padding-right: 30px;
                    box-sizing: border-box;
                }

                .title{
                    width: 30%;
                    padding-right: 0px;
                    box-sizing: border-box;
                }

                .description{
                    width: 40%;

                    p{
                        font-size: rem(30);
                        line-height: rem(32);

                        &:first-child{
                            margin-top:0;
                        }
                        &:last-child{
                            margin-top:0;
                        }
                    }
                }

                img{
                    width: 33%;
                }
            }

            .buttons{
                position: relative;
                z-index: 4;
                display:flex;
                justify-content: space-between;
                //makes the container impossible to block the click on the tile, align children towards top of the tile.
                height:0;
                align-items: flex-end;

                .tag{
                    cursor: auto;
                    pointer-events: none;
                }

                a, button{
                    transition: all $duration $ease;
                }
            }

            .mobile{
                display: none;
            }

            .hover-wrapper{
                position: absolute;
                z-index:1;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                opacity:0;
                pointer-events: none;
                border-radius: 20px;
                overflow: hidden;

                transition: opacity $duration cubic-bezier(.24,.76,.22,.94);

                &:after{
                    content: ' ';
                    background: rgba(0,0,0,0.35);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top:0;
                    left:0;
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: scale $duration $ease;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin-block: 100px;
        .centered-content {
            .card-with-content {
                min-height: 270px;

                .buttons{
                    height:auto;
                }
            }
        }
    }
    @media only screen and (max-width: $xs-max) {
        margin-block: 80px;
        .centered-content {
            .card-with-content {

                .buttons{
                    height:auto;
                }

                &.small-text{
                    .infos{
                        .title{
                            h3{
                                margin-bottom: 16px;
                                font-size: rem(30);
                                line-height: rem(32);
                            }
                        }

                        .description{
                            p{
                                @include text-s-medium();
                            }
                        }
                    }

                    .buttons{
                        position: relative;
                        left:0;
                        bottom:0;
                        margin-bottom:  0px;
                    }
                }

                &.homepage{
                    .infos{
                        .extra-title, .title, .description{
                            width: 100%;
                        }

                        .title{
                            padding-bottom: 0px;

                            h3{
                                margin-bottom: 16px;
                                font-size: rem(30);
                                line-height: rem(32);
                            }
                        }

                        img.hover{
                            display: none;
                        }
                    }

                    .buttons{
                        position: relative;
                        left:0;
                        bottom:0;
                        margin-bottom:  56px;
                    }

                    .mobile{
                        display:block;
                    }
                }

                .infos{
                    flex-direction: column;

                    .extra-title, .title, .description{
                        width: 100%;
                    }

                    .extra-title h3{
                        margin-bottom: 16px;
                        font-size: rem(136);
                        line-height: rem(120);
                    }

                    .title h3{
                        margin-bottom: 35px;
                        font-size: rem(54);
                        line-height: rem(48);
                    }
                }
            }
        }
    }
}
