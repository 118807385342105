@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
  &-gallery {
    overflow: hidden;

    .title{
      p{
        @include text-medium();
        margin-bottom: 25px;
      }
    }

    &.masonry{
      .swiper-wrapper{
        @include grid(var(--column-amount), var(--gutter-width));
        .gallery-slide{
          img{
            width: 100%;
          }
          &:hover{
            cursor: unset;
            img{
              cursor: pointer;
            }
          }
          &:active{
            cursor: unset;
          }

          &:last-child{
            padding-right: unset;
          }

          &:nth-child(1n){
            @include col(5);
            @include col-start(1);
          }
          &:nth-child(2n){
            @include col(7);
            @include col-start(6);
          }
        }
      }
    }

    .swipper-container{
      @include col(12);
      @include col-start(1);
    }
    .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      column-gap: var(--gutter-width);

      .gallery-slide {
        // min-width calculates the width of 5 columns
        min-width: calc(5 * (100% / var(--column-amount)));
        max-width: calc(5 * (100% / var(--column-amount)));
        margin-bottom: auto;

        &:last-child{
          padding-right: calc(var(--gutter-width) * 3);
        }

        img{
          width: 100%;
        }

        &:hover{
          cursor: grab;
        }
        &:active{
          cursor: grabbing;
        }

        p {
          margin-inline: 0;
          margin-block: 6px 8px;
          @include text-xs-medium();
        }
      }

    }
    @media only screen and (max-width: $sm-max){
      .title{
        p{
          margin-bottom: 16px;
        }
      }
    }
    @media only screen and (max-width: $xs-max){
      &.masonry {
        .swiper-wrapper {
          .gallery-slide{
            &:nth-child(1n), &:nth-child(2n){
              @include col(12);
              @include col-start(1);
            }

          }
        }
      }
      .swiper-wrapper{
        .gallery-slide{
          min-width: calc(9 * (100% / var(--column-amount)));
        }
      }
    }

  }
}
