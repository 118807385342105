@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

.primary-btn{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include text-s-medium();
    border-radius: 100px;
    color: map-get($colors-ui-buttons, text-dark);
    border: 1px solid map-get($colors-ui-buttons, border);
    overflow: hidden;
    position: relative;
    padding:0;
    min-width: 40px;
    background: none;

    &:before, &:after{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        text-align: left;
    }

    &:after{
        content: attr(data-replace);
        height: 100%;
        top:0;
        transform-origin: 50% 100%;
        transform: translate3d(0, 200%, 0);
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        padding: 5px 12px 4px 12px;
    }

    &:hover{
        &:before{
            transform-origin: 50% 0;
            transform: scaleY(1);
        }
        &:after{
            transform: translate3d(0, 0, 0);
        }

        span:not([class^="icon-"]){
            transform: translate3d(0, -200%, 0);
        }
    }

    span{
        padding: 5px 12px;
        display: inline-block;
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &.smaller{
        @include text-xs-medium();

        span{
            padding: 4px 6px;
            @include text-xs-medium();
        }

        &:after{
            padding: 4px 6px;
        }
    }

    &.dark, &.active{
        background-color: map-get($colors-ui-buttons, primary-bg-dark);
        color: map-get($colors-ui-buttons, text-light);
        border-color: map-get($colors-ui-buttons, border-dark);
    }

    &.dark-border{
        border-color: map-get($colors-ui-buttons, border-dark);
    }

    @media only screen and (max-width: $xs-max) {
        @include text-xs-medium();

    }
}


.primary-btn.medium{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include text-s-medium();
    border-radius: 100px;
    color: map-get($colors-ui-buttons, text-dark);
    border: 1px solid map-get($colors-ui-buttons, border);
    overflow: hidden;
    position: relative;
    padding:0;
    min-width: 40px;
    background: #c2ceea;

    &:before, &:after{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        text-align: left;
    }

    &:after{
        content: attr(data-replace);
        height: 100%;
        top:0;
        transform-origin: 50% 100%;
        transform: translate3d(0, 200%, 0);
        transition: transform .4s cubic-bezier(0.76, 0, 0.24, 1);
        padding: 23px 30px 22px 30px;
    }

    &:hover{
        &:before{
            transform-origin: 50% 0;
            transform: scaleY(1);
        }
        &:after{
            transform: translate3d(0, 0, 0);
        }

        span:not([class^="icon-"]){
            transform: translate3d(0, -200%, 0);
        } 
    }

    span{
        padding: 23px 30px;
        display: inline-block;
        transition: transform .4s cubic-bezier(0.76, 0, 0.24, 1);
    }

    
    &.active{
        background-color: map-get($colors-ui-buttons, primary-bg-dark);
        color: map-get($colors-ui-buttons, text-light);
        border-color: map-get($colors-ui-buttons, border-dark);
    }

    @media only screen and (max-width: $xs-max) {
        @include text-s-medium();

        &:after{
            padding: 15px 25px 14px 25px;
        }

        span{
            padding: 15px 25px;
        }
    }
}

.primary-btn.big{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include text-s-large();
    border-radius: 100px;
    color: map-get($colors-ui-buttons, text-dark);
    border: 1px solid map-get($colors-ui-buttons, border);
    overflow: hidden;
    position: relative;
    padding:0;
    min-width: 40px;
    background: #c2ceea;

    &:before, &:after{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        text-align: left;
    }

    &:after{
        content: attr(data-replace);
        height: 100%;
        top:0;
        transform-origin: 50% 100%;
        transform: translate3d(0, 200%, 0);
        transition: transform .4s cubic-bezier(0.76, 0, 0.24, 1);
        padding: 23px 30px 22px 30px;
    }

    &:hover{
        &:before{
            transform-origin: 50% 0;
            transform: scaleY(1);
        }
        &:after{
            transform: translate3d(0, 0, 0);
        }

        span:not([class^="icon-"]){
            transform: translate3d(0, -200%, 0);
        } 
    }

    span{
        padding: 23px 30px;
        display: inline-block;
        transition: transform .4s cubic-bezier(0.76, 0, 0.24, 1);
    }

    
    &.active{
        background-color: map-get($colors-ui-buttons, primary-bg-dark);
        color: map-get($colors-ui-buttons, text-light);
        border-color: map-get($colors-ui-buttons, border-dark);
    }

    @media only screen and (max-width: $xs-max) {
        @include text-s-medium();

        &:after{
            padding: 15px 25px 14px 25px;
        }

        span{
            padding: 15px 25px;
        }
    }
}
