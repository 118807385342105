@use "../../../config" as *;
@use "../../../mixins" as *;

.subjects-listing {

    .heading{
        h2{
            margin-bottom: 16px;
        }
        p{
            margin: 0;

            &:first-of-type{
                margin-top: 40px;
            }

            &:last-of-type{
                margin-bottom: 16px;
            }
        }
    }

    .filters{
        margin-bottom: 40px;
    }

    .subjects-list{
        @include grid(12, var(--gutter-width));
        .subject{
            position: relative;
            padding:16px 16px 53px;
            @include col(4);
            box-shadow: 0px 5px 10px rgba(18, 21, 26, 0.2);
            border-radius: 13px;
            margin-bottom: 40px;
            overflow: hidden;

            a{
                position: absolute;
                z-index:2;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
            }

            .subtitle{
                margin:16px 0;
                @include text-xs-medium();
            }

            .tags{
                display:flex;
                flex-wrap: wrap;
                gap: 10px;

                .primary-btn{
                    position: relative;
                    z-index:3;
                    @include text-xs-medium();
                    height: 26px;
                    box-sizing: border-box;
                }

                ul{
                    display: inline-flex;
                    align-items: flex-start;
                    gap: 10px;
                    list-style: none;
                    margin:0;
                    padding:0;

                    li{
                        display:flex;
                    }

                    &.school-subject{
                        button{
                            background-color: map-get($colors-programs, school-subject);
                            border-color: map-get($colors-programs, school-subject);
                        }
                    }
                }
            }

            .objectives{
                @include text-xs-regular();
                padding-left: 25px;

                li{
                    margin-bottom: 2px;
                }
            }

            p.description{
                @include text-xs-regular();
                color: map-get($colors-programs, subject-description);
            }

            .tag{
                position: absolute;
                z-index:1;
                bottom:0;
                left:0;
                width: 100%;
                height: 53px;
                display:flex;
                gap: 10px;
                align-items: center;
                justify-content: center;

                @include text-xs-medium();

                &.lavender{
                    background-color: map-get($colors-backgrounds, lavender);
                }
                &.yellow{
                    background-color: map-get($colors-backgrounds, yellow);
                }
                &.aqua{
                    background-color: map-get($colors-backgrounds, aqua);
                }
                &.teal{
                    background-color: map-get($colors-backgrounds, teal);
                }
                &.pink{
                    background-color: map-get($colors-backgrounds, pink);
                }
                &.blue{
                    background-color: map-get($colors-backgrounds, blue);
                }

                svg{
                    max-height: 16px;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .subjects-list{
            .subject{
                @include col(6);
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .subjects-list{
            .subject{
                @include col(12);
            }
        }
    }
}
