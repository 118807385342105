@use "../../../config" as *;
@use "../../../mixins" as *;

.partners-logo {
    margin-top: 90px;
    margin-bottom: 150px;

    img{
        max-height: 200px;
        max-width: none;
        width: auto;

        &.landscape{
            max-height: none;
            max-width: 300px;
            width: auto;
        }
    }


    @media only screen and (max-width: $sm-max) {
        margin-top: 70px;
        margin-bottom: 100px;
    }
}
