@use "../../../config" as *;
@use "../../../mixins" as *;

.iframe-content {
    margin-block: 150px;

    .heading{
        @include col(8);
        margin-bottom: 56px;

        .description{
            margin-top: 22px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin-block: 100px;

        .heading {
            @include col(12);
            margin-bottom: 36px;
        }
    }
}
