@use "../../../config" as *;
@use "../../../mixins" as *;

.boat-location-map {
    .description{
        margin-bottom: 16px;

        p{
            &:first-child{
                margin-top:0;
            }
            &:last-child{
                margin-bottom:0;
            }
        }
    }
}
