@use "../../../config" as *;
@use "../../../mixins" as *;

.hero{
    padding-top: 75px;

    .centered-content{
        min-height: 415px;
        height: 100%;

        h1{
            margin-bottom: 15px;
        }

        .title-ctn{
            @include col(10);
            @include col-start(1);
            align-self: end;
            margin-top: 50px;

            .description{
                margin-block: -20px 50px;
            }
        }

        svg{
            position: absolute;
            right: 0;
            bottom:16px;
            max-width: 150px;
        }
    }

    figure{
        margin:0;
        position: relative;

        &:after{
            position: absolute;
            z-index:1;
            bottom:0;
            left:0;

            content: ' ';
            width: 100%;
            height: 80px;
            background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%);
        }

        img{
            display:block;
            width: 100%;
        }

        figcaption{
            position: absolute;
            z-index:2;
            bottom: 15px;
            right: 15px;
            color: $white;
            font-size: 18px;
            line-height: 20px;
        }
    }


    @media only screen and (max-width: $sm-max) {
        .centered-content{
            min-height: 275px;

            svg{
                max-width: 100px;
            }
        }

        figure{

            figcaption{
                @include text-xs-medium();
            }
        }

    }

    @media only screen and (max-width: $xs-max) {
        .centered-content{
            min-height:0;

            svg{
                bottom: 0;
                margin: 60px 0 0;
                position: relative;
            }
        }
    }
}
