@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-testimony {
        overflow: hidden;

        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;

            .testimonial {
                padding: 16px;
                min-width: 310px;
                background-color: map-get($colors-pagebuilder, testimonials-bg);
                max-width: 310px;
                margin-bottom: auto;

                &:hover{
                    cursor: grab;
                }
                &:active{
                    cursor: grabbing;
                }

                p {
                    margin-block: 0 30px;
                    @include text-s-medium();
                }

                span {
                    @include text-xs-medium();
                    color: map-get($colors-pagebuilder, testimonials-text-light);
                }
            }

        }

    }
}
