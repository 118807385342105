@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

// Select base theme
.select-wrapper {
    position: relative;
    height: 26px;
    min-width: 100px;

    [me\:skin\:type="select"] {
        display: block;
        width: 100%;
        cursor: pointer;
        z-index: 2;
        border:1px solid black;
        border-radius: 12px;
        overflow: hidden;

        [me\:skin=select] {
            background: none;
            border: none;
        }

        &:hover{
            [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
                span.icon-chevron {
                    transform: translateY(1px);
                }
            }
        }

        &.is-open, &.is-opening {
            [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
                span.icon-chevron {
                    transform: rotate(180deg);
                }
            }
        }

        [me\:skin\:selection] {
            z-index: 99;
            font-size: rem(14);
            line-height: rem(24);
            color: black;
            min-height: 24px;
            max-height: 24px;
            max-width: 160px;
            box-sizing: border-box;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0px 30px 0 8px;
            transition: all 200ms $ease-in-out;
        }

        span.icon-chevron {
            position: absolute;
            right: 4px;
            top: 0px;
            width: 24px !important;
            transition: transform 200ms $ease-in-out-quad;
        }

        [me\:skin\:theme="skinMe-select"], [me\:skin\:id] {
            [me\:skin\:choices] {
                position: relative;
                width: auto;
                right: 0;
                left: 0;
                transition: height 0.5s ease-in-out;
                max-height: 140px;
                background: map-get($colors-article-infos, bg);
                border: none!important;

                .choice {
                    @include text-xs-medium();
                    padding: 5px 8px;
                    min-height: 24px;
                    background: map-get($colors-article-infos, bg);
                    transition: all 200ms ease-in-out;
                    box-sizing: border-box;
                    max-width: 140px;

                    &:hover, &:active, &:focus {
                        background: $white;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $sm-max) {

}

@media only screen and (max-width: $xs-max) {

}
