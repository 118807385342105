@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-news {
        @include grid(12, var(--gutter-width));

        .heading{
            @include grid(12, var(--gutter-width));
            align-items: flex-end;
            margin-bottom: 16px;

            h3 {
                @include col(10);
                display:block;
                margin:0;
            }

            .button{
                @include col(2);
                display:flex;
                justify-content: flex-end;

                .primary-btn{
                    @include text-xs-regular();
                }
            }
        }

        .news-list {
            display:flex;
            flex-wrap:wrap;
            gap: var(--gutter-width);

            .news {
                @include col(6);
                @include grid(6, var(--gutter-width));
                width: calc(50% - ( var(--gutter-width) / 2 ));

                border-top: 1px solid map-get($colors-base, borders);
                padding-top: 16px;
                margin-bottom: 30px;

                .col{
                    @include col(4);
                    display:flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-between;

                }

                a.infos {
                    color: map-get($colors-base, text);
                    @include grid(4,  var(--gutter-width));

                    &:hover + .image {
                        img {
                            scale: 1.07;
                        }
                    }

                    .metas {
                        @include text-xs-medium();
                        @include col(3);
                        display: block;
                        margin-bottom: 8px;
                    }

                    .title{
                        @include col(3);
                    }
                }

                a.image {
                    @include col(2);
                    border-radius: 10px;
                    overflow: hidden;
                    transform: translateZ(0);
                    font-size: 0;
                    line-height: 0;
                    max-height: 155px;

                    &:hover {
                        img {
                            scale: 1.07;
                        }
                    }

                    img {
                        object-fit: cover;
                        width: 108%;
                        height: 100%;
                        scale: 1.001;
                        transition: scale 1s ease;

                    }
                }

                .tags {
                    list-style: none;
                    padding: 0;
                    margin: 36px 0 0;
                    display: flex;
                    gap: 5px;

                    .primary-btn.dark{
                        pointer-events: none;
                    }

                    span{
                        cursor: auto;

                        &.label{
                            padding-left:0;
                        }
                    }

                    li {
                        line-height: 0;
                    }
                }

                .mobile{
                    display:none;
                }

                .primary-btn {
                    @include text-xs-regular();
                    max-height: 26px;
                }
            }
        }

        @media only screen and (max-width: $sm-max) {

            .heading{
                h2 {
                    @include col(12);
                }

                .button{
                    margin-top: 10px;
                    @include col(12);
                }
            }


            .news-list {
                flex-direction: column;
                gap: 20px;
                .news {
                    @include col(12);
                    @include grid(12, var(--gutter-width));
                    margin-bottom:0;
                    width: 100%;

                    .col{
                        @include col(8);
                        height: auto;
                        .tags{
                            display:none;
                        }
                    }

                    a.infos {
                        .metas {
                            @include text-xs-mobile-regular();
                            @include col(8);
                        }

                        .title{
                            @include col(8);
                        }
                    }

                    a.image {
                        @include col(4);
                    }

                    .tags{
                        margin-top: 16px;
                    }

                    .mobile{
                        display:flex!important;
                        flex-wrap:wrap;
                    }
                }
            }
        }
    }
}
