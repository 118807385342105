@use "config" as *;
@use "functions" as *;
@use "mixins" as *;

//
// Root styles
// -----------------------------------------------------------------------------

html {
    --column-amount: 12;
    --gutter-width: #{$gutter-width-lg};
    --font-size: 28px;

    font-size: var(--font-size);
    scroll-behavior: smooth;

    &.in-transition{
        scroll-behavior: auto;
    }

    &.no-scroll{
        overflow: hidden;
    }

    @media only screen and (max-width: $md-max) {
        --column-amount: 12;
        --gutter-width: #{$gutter-width-md};
    }

    @media only screen and (max-width: $sm-max) {
        --column-amount: 12;
        --gutter-width: #{$gutter-width-sm};
    }

    @media only screen and (max-width: $xs-max) {
        --column-amount: 12;
        --gutter-width: #{$gutter-width-xs};
    }
}

body {
    overflow-x: hidden;

    --text-color: #{$black};
    --background-color: #{$white};
    @include text-medium();

    &.no-scroll{
        overflow: hidden;
    }

    #site-ctn{
        background-color: map-get($colors-base, background);
        color: map-get($colors-base, text);
    }

    .lavender{
        background-color: map-get($colors-backgrounds, lavender);
    }
    .yellow{
        background-color: map-get($colors-backgrounds, yellow);
    }
    .aqua{
        background-color: map-get($colors-backgrounds, aqua);
    }
    .teal{
        background-color: map-get($colors-backgrounds, teal);
    }
    .pink{
        background-color: map-get($colors-backgrounds, pink);
    }
    .blue{
        background-color: map-get($colors-backgrounds, blue);
    }

    @media only screen and (max-width: $sm-max) {
        #site-ctn{

        }
    }
}

body.preload *{
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
}

.grecaptcha-badge{
    display:none!important;
}

#site-ctn{

    .mobile-hidden{
        display: block !important;
    }

    .mobile-only{
        display: none !important;
    }

    @media only screen and (max-width: $sm-max){

        .mobile-hidden{
            display: none !important;
        }

        .mobile-only{
            display: block !important;
        }
    }
}

//
// Heading styles
// -----------------------------------------------------------------------------

h1, .like-h1 {
    @include selecta-medium();
    font-size: rem(136);
    line-height: rem(120);
    margin: 0;

    @media only screen and (max-width: $md-max) {
        font-size: rem(100);
        line-height: rem(90);
    }
}

h2, .like-h2 {
    @include selecta-medium();
    font-size: rem(80);
    line-height: rem(74);
    margin: 0 0 55px;
}

h1, .like-h1,
h2, .like-h2 {
    @media only screen and (max-width: $sm-max) {
        font-size: rem(42);
        line-height: rem(40);
        margin: 0 0 16px;
    }
}

h3, .like-h3 {
    @include selecta-medium();
    font-size: rem(54);
    line-height: rem(48);
    margin: 0;
    @media only screen and (max-width: $sm-max) {
        // currently used in pagebuilder quote
        font-size: rem(30);
        line-height: rem(32);
    }
}

h4, .like-h4 {
    @include selecta-medium();
    font-size: rem(42);
    line-height: rem(40);
    margin: 0;

    @media only screen and (max-width: $sm-max) {
        font-size: rem(30);
        line-height: rem(32);
    }
}

h5, .like-h5 {
    @include selecta-medium();
    font-size: rem(30);
    line-height: rem(32);
    margin: 0;
    @media only screen and (max-width: $sm-max) {
        font-size: rem(22);
        line-height: rem(24);
    }
}

h6, .like-h6 {
    @include selecta-medium();
    font-size: rem(22);
    line-height: rem(24);
    margin: 0;
    @media only screen and (max-width: $sm-max) {

    }
}

//
// Text styles
// -----------------------------------------------------------------------------

p {}

//
// Link styles
// -----------------------------------------------------------------------------

a {
    &:hover, &:focus, &:active, &:visited {
        color: var(--text-color);
    }
}

//
// List styles
// -----------------------------------------------------------------------------

ul {}

ol {}

li {}

//
// Form styles
// -----------------------------------------------------------------------------

input {}
