@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-links {

        h2{
            @include col(6);
            @include col-start(1);
        }
        .description{
            @include col(8);
            @include col-start(1);
            margin-top:0;
            margin-bottom: 16px;
            p{
                &:first-child{
                    margin-top:0;
                }
                &:last-child{
                    margin-bottom:0;
                }
            }
        }

        a{
            border-top: 1px solid gray;
            color: gray;
            display:block;
            padding: 8px 30% 8px 8px;
            @include text-medium();
            transition: color 0.2s cubic-bezier(.2,.67,.05,1.01),background-color 0.2s cubic-bezier(.2,.67,.05,1.01);

            &:hover{
                background-color: black;
                color: white;
            }
        }

        @media only screen and (max-width: $sm-max){
            h2{
                @include col(12);
                @include col-start(1);
            }
            .description {
                @include col(12);
                @include col-start(1);
            }

            a{
                font-size: 28px!important;
                line-height: 32px!important;
                margin: 0 16px;
                padding: 8px;
            }
        }
    }
}
