@use "../../../config" as *;
@use "../../../mixins" as *;

//IMPORTANT: OVERRIDE SOME STYLING DONE BY A 3rd PARTY SCRIPT GENERATING THE FORM
.newsletter-form{
    .centered-content{
        form{
            max-width: none;
            border: none;

            background-color: map-get($colors-newsletter-form, background)!important;
            border-radius: 0 !important;

            div[data-style="minimal"]{
                padding: 25px 22px 50px !important;
            }

            div, p, input, h2{
                color: map-get($colors-base, text) !important;
            }

            h2{
                position: relative;
                @include selecta-medium();
                font-size: 54px;
                line-height: 48px;
                text-align: left;
                padding-bottom: 22px;
                margin:0 0 22px!important;
                max-width: 75%;

                &:after{
                    position: absolute;
                    left:0;
                    bottom:0;

                    content: ' ';
                    border-bottom:1px solid map-get($colors-base, text);
                    width: 75%;
                    height:1px;
                }
            }

            .formkit-subheader, .formkit-guarantee, .formkit-powered-by-convertkit-container{
                display:none;
            }

            .formkit-fields{
                margin-top: 47px;

                .formkit-field{
                    width: 23.5%;
                    margin-right: 2%;
                    flex: none;

                    &:nth-child(4){
                        margin-right:0;
                    }

                    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: map-get($colors-newsletter-form, placeholder);
                    }

                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: map-get($colors-newsletter-form, placeholder);
                    }

                    ::-ms-input-placeholder { /* Microsoft Edge */
                        color: map-get($colors-newsletter-form, placeholder);
                    }
                }

                button{
                    background-color: map-get($colors-newsletter-form, button)!important;
                    margin:0;
                    justify-self: flex-end;
                    max-width: 130px;
                    padding: 4px 12px;

                    span{
                        padding:0;
                    }
                }
            }

        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content{
            form{
                h2 {
                    font-size: 30px;
                    line-height: 32px;
                    max-width: 100%;
                }

                .formkit-fields{

                    .formkit-field{
                        width: 49%;
                        margin-right: 2%;
                        flex: none;

                        &:nth-child(2),&:nth-child(4){
                            margin-right:0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content{
            form{
                .formkit-fields{

                    .formkit-field{
                        width: 100%;
                        margin-right: 0%;
                    }
                }
            }
        }
    }
}
