@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

header{
    top:0;
    width: 100%;
    min-height: 65px;
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 99;
    background: white;
    transition: opacity 0.3s ease, background 0.3s ease;
    opacity:1;

    &.hidden-scroll:not(.opened){
        opacity:0;
        pointer-events: none;
    }

    &.top-scroll{
        background: transparent;
    }

    &.opened{
        .backdrop{
            opacity:0.20;
            pointer-events: auto;
        }
    }

    .backdrop{
        content: ' ';
        background: black;
        position: fixed;
        z-index:1;
        top:0;
        left:0;
        right:0;
        bottom:0;
        opacity:0;
        pointer-events: none;

        transition: opacity 0.4s ease;
    }

    &.homepage:not(.opened){
        background: transparent;

        .icon-ecomaris-small{
            display:none;
        }

        .inner-wrapper {

            &:after {
                opacity: 0;
            }

            .primary-btn{
                border-color: map-get($colors-hero-homepage, text);
                color: map-get($colors-hero-homepage, text);

                &.dark{
                    color: black;
                    background-color: map-get($colors-hero-homepage, text);
                }
            }

            .left-content{
                .icon-ecomaris-small{
                    width: 195px;
                }
            }

            .right-content{
                ul{
                    li{
                        .hamburger-icon {
                            span {
                                background-color: map-get($colors-hero-homepage, text);
                            }
                        }
                    }
                }
            }
        }
    }

    .inner-wrapper{
        position: relative;
        z-index:2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: #000;
            bottom: -10px;
            left:0;
            opacity: 1;

            transition: opacity 0.3s ease;
        }


        .primary-btn{
            transition: border-color 0.3s ease, color 0.3s ease;

            &.dark{
                transition: background-color 0.3s ease;
            }
        }

        .left-content{
            position: relative;
            z-index:2;

            .icon-ecomaris-small{
                width: 195px;

                svg{
                    path{
                        fill: black;
                    }
                }
            }

        }

        .right-content{

            ul{
                padding: 0;
                margin:0;
                display: flex;
                list-style: none;
                gap: 8px;

                li{
                    &:nth-child(1), &:nth-child(2){
                        position: relative;
                        z-index:2;
                    }

                    &:nth-child(3) {
                        display: flex;
                        align-items: center;
                    }

                    .hamburger-icon {
                        width: 30px;
                        height: 20px;
                        position: relative;
                        cursor: pointer;

                        span {
                            display: block;
                            width: 100%;
                            height: 2px;
                            background-color: #000;
                            position: absolute;
                            transition: all 0.3s ease-in-out;
                            top: 50%;
                            left: 0;
                            transform-origin: center;
                            z-index: 999;
                            &:first-child {
                                margin-top: -5px;
                                opacity: 1;
                            }

                            &:last-child {
                                margin-top: 3px;
                            }
                        }

                        &.active {
                            span:first-child {
                                opacity: 0;
                                transition: all ease 250ms;
                            }

                            span:last-child {
                                transform: translateY(-50%);
                                margin-top: 0;
                            }
                        }
                    }

                    /* Menu items */
                    .menu-items {
                        pointer-events: none;
                        top: -25px;
                        left: calc(var(--gutter-width) * -1);
                        width: calc(100% + var(--gutter-width) * 2);

                        background-color: #fff;
                        position: absolute;
                        z-index:1;
                        list-style-type: none;
                        opacity: 0;
                        min-height: 0;
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;
                        margin: 0;
                        padding: 86px 0 50px;
                        display: flex;
                        flex-direction: column;
                        @include text-medium();

                        li{
                            span{
                                display:inline-block;
                                margin-top: 8px;
                                @include text-s-medium();
                                padding: 5px var(--gutter-width);
                            }
                            a{
                                transition: all 0.3s ease-in-out;
                                line-height: 0;
                                width: 100%;
                                display: block;
                                padding: 5px var(--gutter-width);
                                margin:0;
                                color: map-get($colors-header, links);
                            }
                        }
                        &.active {
                            pointer-events: auto;
                            opacity: 1;
                            min-height: 500px; /* Adjust this value to control the height of the menu */
                            transition: all 0.65s cubic-bezier(.18,1,0,1.01);
                            li{
                                a{
                                    line-height: 1;
                                    transition: all 1.2s cubic-bezier(.18,1,0,1.01);
                                    &:hover{
                                        background-color: map-get($colors-header, hover);
                                    }
                                }

                            }
                        }
                    }
                }

            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        height: 56px;
        min-height: 56px;

        .inner-wrapper{

            &:after{
                bottom: -15px;
            }

            .left-content{
                .icon-ecomaris-small{
                    width: 100px;
                }
            }

            .right-content{
                ul{
                    gap: 4px;

                    li{
                        .hamburger-icon {
                            width: 24px;
                            height: 16px;
                            margin-left:8px;
                        }

                        .primary-btn{
                            @include text-xs-medium();

                            span{
                                padding: 4px 6px;
                                @include text-xs-medium();
                            }

                            &:after{
                                padding: 4px 6px;
                            }
                        }

                        /* Menu items */
                        .menu-items {
                            padding: 60px 0 30px;
                            padding: 60px 0 30px;

                            @include text-s-medium();

                            li{
                                span{
                                    @include text-xs-medium();
                                }
                                a.like-h4{
                                    font-size: rem(22);
                                    line-height: rem(24);
                                    padding-top:1px;
                                    padding-bottom:1px;
                                }
                            }

                            &.active {

                                min-height: 300px; /* Adjust this value to control the height of the menu */
                            }
                        }
                    }

                }
            }
        }
    }
}
