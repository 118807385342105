@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-cta-banner {
        border-radius: 20px;

        .centered-content{
            min-height: 700px;
            max-height: 900px;
            display:flex;
            grid-column-gap: 0;
            align-items: stretch;
            border-radius: 20px;
            overflow: hidden;

            .infos{
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                width: 50%;
                background-color: map-get($colors-pagebuilder, cta-banner-bg);
                padding: 32px 24px 24px;
            }
            img{
                width: 25%;
                object-fit: cover;
            }
        }

        @media only screen and (max-width: $sm-max) {
            .centered-content{
                min-height: 0;
                flex-wrap: wrap;

                .infos{
                    width: 100%;
                    min-height: 340px;
                    padding: 20px 16px 16px;
                    box-sizing: border-box;
                }
                img{
                    flex: 1;
                    min-height: 340px;
                }
            }
        }
    }
}
