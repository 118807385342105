@import "../../config";
@import "../../mixins";
@import "../../functions";

.notification-header{
    position: fixed;
    z-index:99;
    bottom:40px;
    left:40px;
    right:40px;
    padding: 40px 30px 50px;
    border-radius: 16px;
    background: $white;
    text-align: center;
    box-shadow: 0px 4px 15px 8px rgba(0,0,0,0.17);


    h4{
        margin-bottom: 33px;
    }
    p{
        margin-bottom: 0;
        @include text-xs-regular();
        a{
            color: map-get($colors-base, text);
            text-decoration: underline;
        }
    }

    .close{
        margin-top: 45px;
    }

    @media only screen and (max-width: $sm-max) {
        padding: 30px 20px;

        h4{
            margin-bottom: 23px;
        }

        .close{
            margin-top: 25px;
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 20px 20px;
        bottom:20px;
        left:20px;
        right:20px;
    }
}
