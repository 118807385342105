@use "../../config" as *;
@use "../../mixins" as *;

.subfooter{
    width: 100%;
    background-color: map-get($colors-footer, secondary-bg);
    margin-top: 55px;
    padding-block: 15px;
    > .centered-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .left-col{
        ul{
            display: flex;
            column-gap: 8px;
            li{
                display: flex;
                align-items: center;
                a{
                    @include text-xs-medium();
                }
            }
        }
    }

    .middle-col{
        @include text-xs-medium();
        text-decoration: underline;

        &:hover{
            text-decoration: none;
        }

        a{
            color: map-get($colors-footer, primary-links-hover);
        }
    }

    .right-col{
        display: flex;
        align-items: center;
        span{
            @include text-xs-medium();
        }
    }

    @media only screen and (max-width: $sm-max) {

        margin-top: 40px;
        .centered-content{
            gap: 16px;
            flex-direction: column;
            justify-items: flex-start;
            align-items: flex-start;
        }
    }
}
