@use "../../../config" as *;
@use "../../../mixins" as *;

.podcast {
    margin-block: 150px;

    .heading{
        margin-bottom: 16px;
    }

    audio{
        width: 100%;
    }
}
