@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-text {
        //successive block-text will have a smaller margin
        + .pagebuilder-text{
            margin: 65px 0;
        }

        .title{
            @include col(6);
            @include col-start(1);

        }
        .description{
            @include col(8);
            @include col-start(1);

            *:first-child{
                margin-top:0;
            }

            *:last-child{
                margin-bottom:0;
            }

            ul, ol{
                padding-left:25px;
                list-style-position: outside;
            }

            h1,h2,h3,h4,h5,h6{
                margin-bottom: 25px;
            }

            figure{
                margin-left:0;
                margin-right:0;
                max-width: 70%;

                figcaption{
                    @include text-xs-regular();
                }
            }

            a{
                color: map-get($colors-base, text);
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }
            }
        }

        .primary-btn{
            margin-top: 55px;
            margin-right: auto;
        }

        @media only screen and (max-width: $sm-max){

            .title{
                @include col(12);
                @include col-start(1);

            }
            .description {
                @include col(12);
                @include col-start(1);

                figure{
                    max-width: 100%;
                }

                ul{
                    padding-left: 16px;
                }


                h1,h2,h3,h4,h5,h6{
                    margin-bottom: 16px;
                }
            }

                .inner-wrapper{
                @include col(12);
            }
        }

    }
}
