@use "../../../config" as *;
@use "../../../mixins" as *;

.article-infos{
    .centered-content{
        .infos{

            p.primary-btn{
                margin:0;
                pointer-events: none;
            }

            .read-time{
                @include text-xs-medium();
                margin-bottom:0;
                margin-top: 4px;
            }
        }

        .extra{
            @include col(3);
            display:flex;
            gap: 16px;
            justify-content: flex-end;
            align-items: center;

            >span{
                @include text-xs-medium();
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content{
            .infos{

                .read-time{
                    border-top: 1px solid map-get($colors-article-infos, border);
                    padding-top: 18px;
                }
            }

            .extra{
                @include col(12);
                justify-content: flex-end;
                align-items: center;
                flex-direction: coolumn;

                .share{
                    align-self: flex-end;
                }

                :first-child{
                    order:2;
                }
                :last-child{
                    order:1;
                }
            }
        }
    }
}
