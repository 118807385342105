@use "../../../config" as *;
@use "../../../mixins" as *;

.filters{
    position: relative;
    z-index: 9;

    .centered-content{
        form{
            display:flex;
            justify-content: space-between;
        }

        .infos{
            display: flex;
            align-items: center;
            gap: 16px;

            >div{
                margin-right:0;
                display: inline-block;
                float:left;
            }

            >span{
                @include text-xs-medium();
            }
        }

        .extra{
            label{
                position: relative;
                padding-right: 40px;
                display:flex;
                align-items: center;

                input{
                    background: none;
                    border: none;
                    @include text-xs-medium();
                    text-align: right;
                }

                button{
                    position: absolute;
                    border: none;
                    background: none;
                    margin:0;
                    cursor:pointer;
                    top: -11px;
                    right:0;
                    display:flex;
                    align-items: center;
                    padding: 8px;

                    &:hover{
                        svg{
                            scale:1.08;
                            rotate: 6deg;
                        }
                    }

                    svg{
                        transition: rotate 0.2s ease, scale 0.2s ease;
                        scale:1.0001;
                        translateZ: 0;
                        rotate: 0deg;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content {
            form {
                flex-wrap: wrap;
                flex-direction: column;
                .extra{
                    margin-top: 0;
                    margin-bottom: 15px;
                    flex: 1;
                    order:1;
                    align-self: flex-end;
                }

                .infos{
                    flex-wrap: wrap;
                    flex: 1;
                    order:2;
                    flex-direction: row;
                }
            }
        }
    }
}
