@use "../../../config" as *;
@use "../../../mixins" as *;

.content-heading{
    margin-top: 15px;

    .centered-content{
        padding: 16px;
        border-radius: 10px;
        background: map-get($colors-article-infos, bg);
        box-sizing: border-box;

        .infos{
            @include col (9);
            line-height:0;

            .article-type{
                padding-left: 22px;
                pointer-events: none;

                span:not(.label){
                    position: absolute;
                    left: 0px;
                    top:50%;
                    transform: translateY(-50%);
                }
            }

            >div{
                display:inline-block;
                margin-right: 32px;

                &:last-child{
                    margin-right:0;
                }

                >span{
                    @include text-xs-medium();
                    margin-right:6px;
                }

                ul{
                    display:inline-block;
                    margin:0 0 10px;
                    padding:0;

                    li{
                        display:inline-block;
                        margin-right: 8px;
                        font-size:0;
                        line-height:0;
                    }
                }
            }

        }

        .extra{
            @include col(3);
            display:flex;
            gap: 16px;
            justify-content: flex-end;
            align-items: center;

            >span{
                @include text-xs-medium();
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content{
            .infos{
                display: flex;
                flex-direction: column;
                @include col(12);

                >div{
                    display:flex;

                    >span{
                        margin-top: 4px;
                    }

                    ul{
                        li{
                            margin-right: 2px;
                            margin-bottom: 4px;

                            &:last-child{
                                margin-bottom:0;
                            }
                        }
                    }
                }
            }

            .extra{
                margin-top: 20px;
                @include col(12);
                justify-content: space-between;
            }
        }
    }
}
