@use "../../../config" as *;
@use "../../../mixins" as *;

.news-listing {
    margin-block: 80px;

    .heading{
        h2{
            margin-bottom: 16px;
        }
    }

    .filters{
        margin-bottom: 40px;
    }

    .news-list{
        @include grid(12, var(--gutter-width));
    }

    .pagebuilder-news{
        .centered-content{
            width: 100%;
        }
    }

}
