@use "../../../config" as *;
@use "../../../mixins" as *;


.lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    z-index:999;
    align-items: center;
    justify-content: center;
    &.open{
        display: flex;
        cursor: pointer;
        img{
            cursor: auto;
        }
    }

    .lightbox-ctn{

        pointer-events: none;
        max-width: 90%;
    }

    @media only screen and (max-width: $xs-max){
        &.open{
            .lightbox-ctn{
                padding-inline: 16px;
                img{
                    max-width: 100%;
                }
            }

        }
    }

}

