@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-profiles {
        @include grid(12, var(--gutter-width));

        .heading{
            @include grid(12, var(--gutter-width));
            align-items: flex-end;
            margin-bottom: 85px;

            h2 {
                @include col(6);
                display:block;
                margin:0;
            }
        }

        .profiles-list {
            display:flex;
            flex-wrap:wrap;
            gap: var(--gutter-width);

            .profile {
                @include col(6);
                @include grid(6, var(--gutter-width));
                width: calc(50% - ( var(--gutter-width) / 2 ));

                border-top: 1px solid map-get($colors-base, borders);
                padding-top: 16px;
                margin-bottom: 30px;

                .col{
                    @include col(4);
                    display:flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-between;

                    &:hover + .image {
                        img {
                            scale: 1.07;
                            &.hover{
                                opacity:1;
                            }
                        }
                    }

                    .infos {
                        color: map-get($colors-base, text);
                        @include grid(4,  var(--gutter-width));

                        .role {
                            @include text-xs-medium();
                            @include col(3);
                            display: block;
                            margin-bottom: 8px;
                        }

                        .title{
                            @include col(3);
                        }
                    }

                    span.linkedin{
                        @include text-medium();
                        color: map-get($colors-pagebuilder, profile-linkedin);
                        padding-top: 5px;
                        padding-left: 5px;
                        margin-bottom: 5px;
                        border-top: 1px solid map-get($colors-pagebuilder, profile-linkedin);
                    }
                }

                a.image, div.image {
                    @include col(2);
                    border-radius: 10px;
                    overflow: hidden;
                    font-size: 0;
                    line-height: 0;
                    position: relative;
                    transform: translateZ(0);

                    &:hover {
                        img {
                            scale: 1.07;
                            &.hover{
                                opacity:1;
                            }
                        }
                    }

                    img {
                        max-height: 155px;
                        object-fit: cover;
                        width: 108%;
                        height: 100%;
                        scale: 1.001;
                        transition: scale 1s cubic-bezier(.2,.67,.05,1.01), opacity 1s cubic-bezier(.2,.67,.05,1.01);

                        &.hover{
                            opacity:0;
                            position: absolute;
                            z-index:2;
                            left:0;
                        }
                    }
                }

                .tags {
                    list-style: none;
                    padding: 0;
                    margin: 36px 0 0;
                    display: flex;
                    gap: 5px;

                    li {
                        line-height: 0;
                    }
                }

                .mobile{
                    display:none;
                }

                .primary-btn {
                    @include text-xs-regular();
                }
            }
        }

        @media only screen and (max-width: $sm-max) {

            .heading{
                h2 {
                    @include col(12);
                }

                .button{
                    margin-top: 10px;
                    @include col(12);
                }
            }


            .profiles-list {
                gap: 20px;
                .profile {
                    @include col(12);
                    @include grid(12, var(--gutter-width));
                    margin-bottom:0;
                    width: 100%;

                    .col{
                        @include col(8);
                        .tags{
                            display:none;
                        }
                    }

                    a.infos {
                        .metas {
                            @include text-xs-mobile-regular();
                            @include col(8);
                        }

                        .title{
                            @include col(8);
                        }
                    }

                    a.image, div.image {
                        @include col(4);
                    }

                    .tags{
                        margin-top: 20px;
                    }

                    .mobile{
                        display:flex!important;
                    }
                }
            }
        }
    }
}
