@use "../../config" as *;
@use "../../mixins" as *;

section, .section {
    .centered-content {
        position: relative;
        width: $container-lg;
        margin: 0 auto;

        @include grid(var(--column-amount), var(--gutter-width));
    }

    @media only screen and (max-width: $md-max) {
        .centered-content {
            width: $container-md;
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content {
            width: $container-sm;
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            width: $container-xs;
            box-sizing: border-box;
        }
    }
}
