@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

[class*="pagebuilder"]{
    margin-block: 150px;

    &:first-of-type, &.small-top-margin{
        margin-top: 90px;
    }
    &:first-of-type, &.medium-top-margin{
        margin-top: 120px;
    }

    &.small-margin{
        margin-block: 56px;
    }

    &.no-margin{
        margin-block: 0;
    }

    @media only screen and (max-width: $sm-max) {
        margin-block: 100px;

        &:first-of-type, &.small-top-margin{
            margin-top: 70px;
        }

        &.small-margin{
            margin-block: 56px;
        }

        &.no-margin{
            margin-block: 0;
        }
    }
}
