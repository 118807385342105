@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagination{
    display:flex;
    justify-content: center;
    align-items: center;

    .previous-page{
        display:flex;
        align-items: center;

        svg{
            position: relative;
            rotate: 90deg;
            left:0;
            transition: left 0.3s ease;
        }

        &:hover{
            svg{
                left: -3px;
            }
        }
    }

    div{
        display:flex;
        a, span{
            border-radius: 5px;
            margin: 0 4px;
            background: transparent;
            padding: 4px;
            min-width: 14px;
            min-height: 14px;
            box-sizing: border-box;
            @include text-xs-medium();
            transition: all 0.3s ease;
            color: $black;
        }

        a:hover, span{
            background: $black;
            color: $white;
        }
    }

    .next-page{
        display:flex;
        align-items: center;

        svg{
            position: relative;
            rotate: -90deg;
            left:0;
            transition: left 0.3s ease;
        }

        &:hover{
            svg{
                left: 3px;
            }
        }
    }
}
