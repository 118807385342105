@use "../../config" as *;
@use "../../mixins" as *;

footer{
    padding-block: 15px 0;
    background-color: map-get($colors-footer, primary-bg);

    ul{
        list-style: none;
        padding: 0;
        margin:0;
    }

    .primary-btn.active{
        pointer-events: none;
    }

    .col-wrapper{
        @include grid(var(--column-amount), var(--gutter-width));

        .left-col{
            @include col-start(1);
            @include col(3);

            li{
                display:flex;
                margin-bottom: 8px;

                &:last-child{
                    margin-bottom:0;
                }

                a{
                    @include text-s-medium();
                    color: map-get($colors-footer, primary-links);

                    &:hover{
                        color: map-get($colors-footer, primary-links-hover);
                    }
                }

            }
        }
        .middle-col{
            @include col-start(7);
            @include col(3);

            display:flex;
            flex-direction: column;
           > a{
                color: map-get($colors-footer, primary-links);

                &:hover{
                    color: map-get($colors-footer, primary-links-hover);
                }
            }

            li{
                @include text-xs-medium();
                margin: 8px 0;
                color: map-get($colors-footer, primary-links-hover);

                a{
                    color: map-get($colors-footer, primary-links-hover);

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        .right-col{
            @include col-start(12);
            @include col(1);
            text-align: right;

            .lang-switcher, .socials{
                ul{
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    li{
                        a{
                            @include text-xs-medium();
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .col-wrapper{
            .left-col{
                @include col-start(1);
                @include col(9);
            }
            .middle-col{
                margin-top: 40px;
                order:3;
                @include col-start(1);
                @include col(12);
            }
        }
    }

}
