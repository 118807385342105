@use "../../../config" as *;
@use "../../../mixins" as *;

.error-page{
    .centered-content{
        margin-top: 286px;
        margin-bottom: 100px;

        .content{
            @include col(6);

            h1{
                margin-bottom:80px;
            }
            h2{
                margin-bottom: 56px;
            }
        }

        .icon-boat{
            margin:0 auto;
            width: 90%;
            max-width: 1065px;

            .water-line1{
                animation: waterLeft 3s linear infinite;
            }

            .water-line2{
                animation: waterLeft 3s 0.2s linear infinite;
            }

            .water-line3{
                animation: waterRight 3s 0.1s linear infinite;
            }

            .water-line4{
                animation: waterRight 3s 0.3s linear infinite;
            }

            .right-arm, .right-arm-shadow{
                transform-origin: top right;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content{
            margin-top: 186px;

            .content{
                @include col(9);

                h1{
                    margin-bottom:56px;
                }
                h2{
                    margin-bottom: 32px;
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content{
            margin-top: 100px;

            .content{
                @include col(12);

                h1{
                    margin-bottom:32px;
                }
                h2{
                    margin-bottom: 16px;
                }
            }
        }
    }

    @keyframes waterLeft {
        0%{
            transform: translateX(0);
        }

        75%{
            transform: translateX(-20px);
            opacity:0;
        }
        78%{
            transform: translateX(10px);
            opacity:0;
        }

        100%{
            transform: translateX(0px);
            opacity:0.15;
        }
    }

    @keyframes waterRight {
        0%{
            transform: translateX(0);
        }

        75%{
            transform: translateX(20px);
            opacity:0;
        }
        78%{
            transform: translateX(-10px);
            opacity:0;
        }

        100%{
            transform: translateX(0px);
            opacity:0.15;
        }
    }
}
