@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-media {
        .title{
            @include col(12);
            @include col-start(1);
            margin-bottom: 16px;
        }
        .media-wrapper{
            @include col(12);
            @include col-start(1);
            @include grid(var(--column-amount), var(--gutter-width));
            .media{
                width: 100vw;
                margin-left: calc(-1 * var(--gutter-width) * 1);
                img, video{
                    aspect-ratio: 16/10;
                    object-fit: cover;
                    width: 100%;
                }
            }

            iframe{
                border:none;
                width: 100%;
                height: auto;
                aspect-ratio: 16/9;
            }

            p{
                @include text-xs-medium();
                margin:0;
                @include col(5);
                @include col-start(1);
            }

        }

        @media only screen and (max-width: $sm-max){
            .media-wrapper{
                p{
                    @include col(12);
                }
            }
        }

    }
}
