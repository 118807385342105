@use "../config" as *;
@use "../functions" as *;
@use "../_mixins/fonts";


@mixin text-xs-regular(){
    @include fonts.selecta-regular();
    font-size: rem(14);
    line-height: rem(16);
}

@mixin text-xs-mobile-regular(){
    @include fonts.selecta-regular();
    font-size: rem(12);
    line-height: rem(14);
}

@mixin text-xs-medium(){
    @include fonts.selecta-medium();
    font-size: rem(14);
    line-height: rem(16);
}

@mixin text-s-medium(){
    @include fonts.selecta-medium();
    font-size: rem(22);
    line-height: rem(24);
    //
    //@media only screen and (max-width: $sm-max) {
    //    font-size: rem(14);
    //    line-height: rem(16);
    //}
}

@mixin text-s-large(){
    @include fonts.selecta-medium();
    font-size: rem(40);
    line-height: rem(44);
    
    @media only screen and (max-width: $sm-max) {
       font-size: rem(30);
       line-height: rem(33);
    }
}



@mixin text-medium(){
    @include fonts.selecta-medium();
    font-size: rem(28);
    line-height: rem(32);

    @media only screen and (max-width: $sm-max) {
        font-size: rem(14);
        line-height: rem(16);
    }
}
