@use "../../../config" as *;
@use "../../../mixins" as *;

.grecaptcha-badge{
    display:none;
}

.custom-form{
    margin-block: 150px;

    .centered-content{
        h2,h5{
            @include col(7);
            display:block;
        }

        form{
            @include col(7);
            @include grid(7, var(--gutter-width));
            margin-top: 22px;
            border-top:1px solid map-get($colors-form, border);

            transition: all 0.3s ease;

            &.loading{
                filter: blur(1px);
                opacity:0.9;
            }

            .messages{
                min-height: 50px;
            }

            &.error{
                .error-msg{
                    opacity:1;
                    display: block;
                }
                .success-msg{
                     display: none;
                 }
            }

            &.success{
                .success-msg{
                    opacity:1;
                    display: block;
                }
                .error-msg{
                    display: none;
                }
            }

            .error-msg{
                display:none;
                opacity:0;
                transition: opacity 0.3s ease;
            }

            .success-msg{
                display:none;
                opacity:0;
                transition: opacity 0.3s ease;
            }

            p{
                margin-top: 22px;
            }

            label, input, textarea{
                @include text-s-medium();
                @include col(5);
            }

            label{
                margin-block: 22px;
            }

            input{
                padding:8px;
                border-radius: 4px;
                border: 1px solid map-get($colors-form, border);
                transition: border-color 0.3s ease;

                &.error{
                    border-color:$error;
                }
            }

            textarea{
                min-height: 150px;
                padding:8px;
                border-radius: 4px;
                border: 1px solid map-get($colors-form, border);

                &.error{
                    border-color:$error;
                }
            }

            button{
                justify-self: flex-start;
                margin-left: 96px;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin-block: 100px;

        .centered-content{
            h2,h5{
                @include col(12);
            }

            form {
                @include col(12);
                @include grid(12, var(--gutter-width));

                label, input, textarea{
                    @include col(7);
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content{
            form {

                label, input, textarea{
                    @include col(12);
                }

                button{
                    justify-self: center;
                    margin-left: 0px;
                }
            }
        }
    }
}
