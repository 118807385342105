// @font-face definitions

@font-face {
  font-family: 'Selecta-Regular';
  src:  url('../fonts/Selecta-Regular.woff2') format('woff2'),
  url('../fonts/Selecta-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Selecta-Medium';
  src:  url('../fonts/Selecta-Medium.woff2') format('woff2'),
  url('../fonts/Selecta-Medium.woff') format('woff');
}

