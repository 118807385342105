@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.breadcrumb{
    display:flex;
    flex-wrap: wrap;
    gap: 8px;

    .spacer{
        @include text-xs-medium();
        color: map-get($colors-base, text);
    }

    a{
        @include text-xs-medium();
        color: map-get($colors-base, text);
        text-decoration: underline;
    }

    a:hover{
        text-decoration: none;
    }

    span{
        @include text-xs-regular();
        color: map-get($colors-base, text);
    }
}
