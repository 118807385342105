@use "../../../config" as *;
@use "../../../mixins" as *;

.pagebuilder {
    &-quote {
        .inner-wrapper{
            @include col(8);
            @include col-start(1);

            h3{
                display:block;


                :first-child{
                    margin-top:0;
                }
                :last-child{
                    margin-bottom:0;
                }

                //&:before{
                //    content: open-quote;
                //    margin-right: 10px;
                //}
                //&:after{
                //    content: close-quote;
                //    margin-left: 10px;
                //}
            }

        }

        @media only screen and (max-width: $sm-max){
            .inner-wrapper{
                @include col(12);
            }
        }

    }
}
