@use "../../../config" as *;
@use "../../../mixins" as *;

.program-listing {
    overflow: hidden;
    margin-block: 80px;

    &.small-top-margin{
        margin-top: 30px;
    }

    .title{
        margin-bottom: 55px;
    }

    .swiper-container{
        position: relative;
    }

    [class*="swiper-button"]{
        position: absolute;
        z-index:9;
        top: 50%;
        width: 23px;
        height: 49px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../images/ui/arrow.png");
    }

    .swiper-button-next{
        right: -16px;
        transform: translateY(-50%);
        &:after{
            content: '';
        }
    }
    .swiper-button-prev{
        left: -15px;
        transform: translateY(-50%) rotate(180deg);

        &:after{
            content: '';
        }
    }

    .swiper-button-disabled{
        opacity:0;
        pointer-events: none;
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        text-align: center;

        .swiper-slide {
            position: relative;
            //each element has 3 columns, and we need to remove 3 gutter between the 4 elements, divided by the amount of columns
            min-width: calc(3 * ((100% - 3 * 16px) / var(--column-amount)));
            max-width: calc(3 * ((100% - 3 * 16px) / var(--column-amount)));
            padding: 32px;
            border-radius: 20px;
            overflow: hidden;
            background-color: map-get($colors-programs, bg);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            height: auto;

            $ease: cubic-bezier(.01,.99,.22,.94);
            $duration: 0.5s;

            transition: padding $duration $ease;

            &:hover{
                padding: 24px 32px;

                &[data-color="lavender"]{
                    .infos{
                        color: map-get($colors-backgrounds, lavender);
                    }
                    .logo{
                        border-color: map-get($colors-backgrounds, lavender);
                    }
                    button{
                        background-color: map-get($colors-backgrounds, lavender);
                    }
                    .logo svg path{
                        fill: map-get($colors-backgrounds, lavender);
                    }
                }
                &[data-color="yellow"]{
                    .infos{
                        color: map-get($colors-backgrounds, yellow);
                    }
                    .logo{
                        border-color: map-get($colors-backgrounds, yellow);
                    }
                    button{
                        background-color: map-get($colors-backgrounds, yellow);
                    }
                    .logo svg path{
                        fill: map-get($colors-backgrounds, yellow);
                    }
                }
                &[data-color="aqua"]{
                    .infos{
                        color: map-get($colors-backgrounds, aqua);
                    }
                    .logo{
                        border-color: map-get($colors-backgrounds, aqua);
                    }
                    button{
                        background-color: map-get($colors-backgrounds, aqua);
                    }
                    .logo svg path{
                        fill: map-get($colors-backgrounds, aqua);
                    }
                }
                &[data-color="teal"]{
                    .infos{
                        color: map-get($colors-backgrounds, teal);
                    }
                    .logo{
                        border-color: map-get($colors-backgrounds, teal);
                    }
                    button{
                        background-color: map-get($colors-backgrounds, teal);
                    }
                    .logo svg path{
                        fill: map-get($colors-backgrounds, teal);
                    }
                }
                &[data-color="pink"]{
                    .infos{
                        color: map-get($colors-backgrounds, pink);
                    }
                    .logo{
                        border-color: map-get($colors-backgrounds, pink);
                    }
                    button{
                        background-color: map-get($colors-backgrounds, pink);
                    }
                    .logo svg path{
                        fill: map-get($colors-backgrounds, pink);
                    }
                }
                &[data-color="blue"]{
                    .infos{
                        color: map-get($colors-backgrounds, blue);
                    }
                    .logo{
                        border-color: map-get($colors-backgrounds, blue);
                    }
                    button{
                        background-color: map-get($colors-backgrounds, blue);
                    }
                    .logo svg path{
                        fill: map-get($colors-backgrounds, blue);
                    }
                }

                .logo{
                    margin: 0px auto;

                    svg{
                        margin: 60px auto;
                    }
                }

                .infos{
                    padding-bottom: 8px;
                }

                .description p{
                    opacity:0;
                    padding-top: 8px;
                }

                .hover-wrapper{
                    opacity:1;

                    button{
                        bottom: 24px;
                        z-index:2;
                    }
                }
            }

            >a{
                position: absolute;
                top:0;
                left:0;
                z-index:3;
                width: 100%;
                height: 100%;
            }

            .infos{
                position:relative;
                margin-bottom: 285px;

                transition: color $duration $ease, padding-bottom $duration $ease;

                .subtitle{
                    margin: 10px 0 0;
                    @include text-xs-medium();
                }
            }

            .logo{
                position:absolute;
                left: 50%;
                top: calc(50% + 25px);
                transform: translate(-50%, -50%);
                margin: 0px auto;
                width: 165px;
                border-top:1px solid black;
                border-bottom:1px solid black;

                transition: margin $duration $ease;

                svg{
                    height: 80px;
                    width:auto;
                    margin: 40px;

                    transition: margin $duration $ease;

                    path{
                        transition: fill $duration $ease;
                    }
                }
            }

            .description p{
                position:relative;
                @include text-xs-medium();

                transition: opacity $duration $ease, padding-top $duration $ease;

                &:first-child{
                    margin-top:0;
                }
                &:last-child{
                    margin-bottom:0;
                }
            }

            .hover-wrapper{
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                opacity:0;

                transition: opacity $duration cubic-bezier(.24,.76,.22,.94);

                &:after{
                    content: ' ';
                    background: rgba(0,0,0,0.35);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top:0;
                    left:0;
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                button{
                    position: absolute;
                    bottom: 32px;
                    left: 50%;
                    transform: translateX(-50%);
                    border:none;

                    transition: bottom $duration $ease, background-color $duration $ease;
                }
            }
        }
    }

    @media only screen and (max-width: $md-max) {
        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            text-align: center;

            .swiper-slide {
                //each element has 4 columns, and we need to remove 2 gutter between the 3 elements, divided by the amount of columns
                min-width: calc(4 * ((100% - 2 * 16px) / var(--column-amount)));
                max-width: calc(4 * ((100% - 2 * 16px) / var(--column-amount)));
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            text-align: center;

            .swiper-slide {
                //each element has 6 columns, and we need to remove 1 gutter between the 2 elements, divided by the amount of columns
                min-width: calc(6 * ((100% - 1 * 16px) / var(--column-amount)));
                max-width: calc(6 * ((100% - 1 * 16px) / var(--column-amount)));
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .swiper-button-next, .swiper-button-prev{
            display:none;
        }

        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            text-align: center;

            .swiper-slide {
                //each element has 4 columns, and we need to remove 2 gutter between the 3 elements, divided by the amount of columns
                min-width: calc(10 * ((100% - 1 * 16px) / var(--column-amount)));
                max-width: calc(10 * ((100% - 1 * 16px) / var(--column-amount)));

                &.swiper-slide-active{
                    padding: 24px 32px;

                    &[data-color="lavender"]{
                        .infos{
                            color: map-get($colors-backgrounds, lavender);
                        }
                        .logo{
                            border-color: map-get($colors-backgrounds, lavender);
                        }
                        button{
                            background-color: map-get($colors-backgrounds, lavender);
                        }
                        .logo svg path{
                            fill: map-get($colors-backgrounds, lavender);
                        }
                    }
                    &[data-color="yellow"]{
                        .infos{
                            color: map-get($colors-backgrounds, yellow);
                        }
                        .logo{
                            border-color: map-get($colors-backgrounds, yellow);
                        }
                        button{
                            background-color: map-get($colors-backgrounds, yellow);
                        }
                        .logo svg path{
                            fill: map-get($colors-backgrounds, yellow);
                        }
                    }
                    &[data-color="aqua"]{
                        .infos{
                            color: map-get($colors-backgrounds, aqua);
                        }
                        .logo{
                            border-color: map-get($colors-backgrounds, aqua);
                        }
                        button{
                            background-color: map-get($colors-backgrounds, aqua);
                        }
                        .logo svg path{
                            fill: map-get($colors-backgrounds, aqua);
                        }
                    }
                    &[data-color="teal"]{
                        .infos{
                            color: map-get($colors-backgrounds, teal);
                        }
                        .logo{
                            border-color: map-get($colors-backgrounds, teal);
                        }
                        button{
                            background-color: map-get($colors-backgrounds, teal);
                        }
                        .logo svg path{
                            fill: map-get($colors-backgrounds, teal);
                        }
                    }
                    &[data-color="pink"]{
                        .infos{
                            color: map-get($colors-backgrounds, pink);
                        }
                        .logo{
                            border-color: map-get($colors-backgrounds, pink);
                        }
                        button{
                            background-color: map-get($colors-backgrounds, pink);
                        }
                        .logo svg path{
                            fill: map-get($colors-backgrounds, pink);
                        }
                    }
                    &[data-color="blue"]{
                        .infos{
                            color: map-get($colors-backgrounds, blue);
                        }
                        .logo{
                            border-color: map-get($colors-backgrounds, blue);
                        }
                        button{
                            background-color: map-get($colors-backgrounds, blue);
                        }
                        .logo svg path{
                            fill: map-get($colors-backgrounds, blue);
                        }
                    }

                    .logo{
                        margin: 0px auto;

                        svg{
                            margin: 60px auto;
                        }
                    }

                    .description p{
                        opacity:0;
                    }

                    .hover-wrapper{
                        opacity:1;

                        button{
                            bottom: 24px;
                            z-index:2;
                        }
                    }
                }
            }
        }
    }
}
