@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

span[class^="icon-"] {
    display: inline-block;

    svg {
        box-sizing: content-box;
        display: block;
        width: 100%;
        margin: 0;
    }

    &.icon-podcast {
        width: 13px;
    }

    &.icon-document {
        width: 17px;
    }
    &.icon-video {
        width: 19px;
    }
    &.icon-gallery {
        width: 16px;
    }
    &.icon-text {
        width: 16px;
    }
    &.icon-search {
        width: 24px;
    }
    &.icon-chevron {
        width: 24px;
    }
    &.icon-ecomaris {
        width: 1328px;
    }
    &.icon-ecomaris-small {
        width: 195px;
    }
    &.icon-boat {
        width: 1065px;
    }
}
