@use "../../../config" as *;
@use "../../../mixins" as *;

.section.partners{
    margin-block: 150px;

    .centered-content {
        h3{
            margin-bottom: 16px;
        }

        ul{
            margin:0;
            padding:0;
            list-style: none;
            display:flex;
            row-gap: 30px;
            column-gap: 50px;
            flex-wrap: wrap;
            align-items: center;

            li{
                img{
                    max-height: 75px;
                    width: auto;
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        margin-block: 100px;

        .centered-content {
            ul {
                gap: 0;

                li{
                    width: 50%;
                    padding-right: 20px;
                    margin-bottom: 20px;
                    box-sizing: border-box;
                }
            }
        }
    }
    @media only screen and (max-width: $xs-max) {
        .centered-content {
            ul {
                gap: 10px;
                flex-direction: column;

                li{
                    width: 100%;
                    padding-right: 0px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}
