@use "../../config" as *;
@use "../../mixins" as *;

.share{
    display:flex;
    position: relative;

    &:hover, &:active{
        .a2a_kit{
            opacity:1;
            pointer-events: auto;
        }
    }

    .a2a_kit{
        pointer-events: none;
        opacity:0;
        background: map-get($colors-article-infos, border);
        position: absolute;
        top: calc(100% + 8px);
        left: 0%;
        width: 150px;
        height: 50px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        transform: translateX(-50%);

        &:before{
            //spacer so we dont lose mouseover in the space between the button and the share container
            content: ' ';
            width: 100%;
            height: 10px;
            position: absolute;
            top: -10px;
            left:0;
        }

        &:after{
            //triangle
            content: ' ';
            width: 0;
            height: 0;
            position: absolute;
            top: -5px;
            left:75%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid map-get($colors-article-infos, border);
        }
    }

    @media only screen and (max-width: $sm-max) {
        .a2a_kit{
            width: 75px;
            height: 75px;
            flex-wrap: wrap;
        }
    }
}
