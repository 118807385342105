@use "../../config" as *;
@use "../../mixins" as *;
@use "../../functions" as *;

.hero-homepage{
    position: relative;
    width : 100%;
    aspect-ratio: 16/9;

    .centered-content{
        position: relative;
        z-index:2;
        height: 100%;

        .icon-ecomaris{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: calc(100% - 100px);
        }

        h1{
            text-align: center;
            margin-bottom: 15px;
            color: map-get($colors-hero-homepage, text);
        }

        .title-ctn{
            @include col(12);
            @include col-start(1);
            align-self: end;
            margin-bottom: 32px;
        }
    }

    video{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.mobile{
            opacity: 0;
        }
    }

    @media only screen and (max-width: $xs-max) {
        aspect-ratio: 9/16;

        video{
            &.desktop{
                opacity:0;
            }

            &.mobile{
                opacity:1;
            }
        }
    }
}
